// customize some Bootstrap variables
$brand-primary: darken(#428bca, 20%);

// the ~ allows one to reference things in node_modules
@import '~bootstrap/scss/bootstrap';

/* ---------------------------------------------------------------------------------------------------------------- */

body
{
    background: #f0ede8;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.box-primary,
.modal-content
{
    margin: 0;
}

.wrapper
{
    color: #555;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.start-box
{
    font-size: 1.25rem;
}

.start-box a
{
    color: #333;
}

.go-2
{
    float: left;
    margin: 0 2rem 0 0;
}

.go-2-consumers,
.go-2-shops,
.go-2-manufacturers
{}

/* ---------------------------------------------------------------------------------------------------------------- */

h3.fix,
h4.fix
{
    font-weight: 400;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.shop-logo-small
{
    border: 1px solid #ccc;
    height: 35px;
    vertical-align: -1.25rem;
    width: 105px;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.alert
{
    padding: 0.75rem 1.25rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

#location_type.custom-control
{
    padding-left: 1rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.pac-container { z-index: 100000; }

/* ---------------------------------------------------------------------------------------------------------------- */

.box
{
    margin-bottom: .5rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

#header
{
}

#header #logo-box
{
    float: left;
    height: 2.5rem;
    margin: 1rem;
    width: 10rem;
}

#header #logo-box a
{
    color: #b66e8c;
    display: block;
    font-size: 1.5rem;
    height: 100%;
    padding-left: 0.5rem;
    text-shadow: 1px 1px #fff;
    width: 100%;
}

#header #language-changer
{
    float: right;
    height: 1.7rem;
    margin: 1rem 1.5rem 1rem 1rem;
    text-align: right;
    width: 10rem;
}

#header #language-changer #language-icon
{
    color: $blue;
    font-size: 1.5rem;
    line-height: 1.5rem;
    vertical-align: sub;
}

#header #language-changer a,
#header #language-changer a:hover,
#header #language-changer a:focus
{
    color: #7cafe6;
}

/* ---------------------------------------------------------------------------------------------------------------- */

a
{
    text-decoration: none;
}

a:hover,
a:focus
{
    text-decoration: underline;
}

.my-menu-main a,
.my-menu-sub a,
.my-menu-dir a,
.box-btn a
{
    text-decoration: none !important;
}

/* ---------------------------------------------------------------------------------------------------------------- */

#footer
{
    color: #777;
    padding: 2rem 0;
    min-height: 8rem;
}

#footer .copyrights
{
    text-align: center;
    margin-bottom: .25rem;
    /* text-shadow: 1px 1px 1px #aaa; */
}

#footer .copyrights .copy-item
{
    display: inline-block;
    padding-right: .35rem;
}

#footer .copyrights .copy-item:last-of-type
{
    padding-right: 0;
}

#footer .official ul#menu-footer
{
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin: 0;
}

ul#menu-footer li
{
    display: inline-block;
    margin-right: .5rem;
    text-shadow: 1px 1px 1px #fff;
}

ul#menu-footer li:last-of-type
{
    margin-right: 0;
}

/* ---------- main-content ------------------------------------------------------------------------------------------------------ */

#main-content-start
{
    padding: 20px;
    background: #f8f8f8;
    border: 1px solid #ddd;
    color: #212529;
}

#main-content
{
    padding: 20px;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-top: none;
    color: #000;
}

.main-content-login
{
    padding: 2rem !important;
}

.login-box
{
    max-width: 1000px;
    margin: 0 auto;
}

/* ---------- card ------------------------------------------------------------------------------------------------------ */

.card
{
    margin-bottom: 1rem;
}

.card-body
{
    background: #f3f3f3;
    padding: 1rem;
}

.card-header h3
{
    font-size: 1.1rem;
}

/* ---------- ---------- ---------- */

.card-primary
{
    border: 1px solid $blue;
}

.card-primary .card-header
{
    background: $blue;
}

.card-primary .card-header h3
{
    color: #FFF;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-info-1
{
    border: 1px solid #ccc;
}

.card-info-1 .card-header
{
    background: #d9edf7;
}

.card-info-1 .card-header h3
{
    color: $blue;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-info-2
{
    border: 1px solid #ccc;
}

.card-info-2 .card-header
{
    background: #f3f3f3;
}

.card-info-2 .card-header h3
{
    color: #3078c5;
    font-weight: 400;
    margin-bottom: 0;
}

.card-info-2 .card-body
{
    background: #fafafa;
}

/* ---------- ---------- ---------- */

.card-info-3
{
    border: 1px solid #ccc;
}

.card-info-3 .card-header
{
    background: #0077f7;
}

.card-info-3 .card-header h3
{
    color: $white;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-success
{
    border: 1px solid #28a745;
}

.card-success .card-header
{
    background: #28a745;
}

.card-success .card-header h3
{
    color: #FFF;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-warning
{
    border: 1px solid #ffc107;
}

.card-warning .card-header
{
    background: #ffc107;
}

.card-warning .card-header h3
{
    color: #212529;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-danger
{
    border: 1px solid #dc3545;
}

.card-danger .card-header
{
    background: #dc3545;
}

.card-danger .card-header h3
{
    color: #FFF;
    font-weight: 400;
    margin-bottom: 0;
}

/* ---------- ---------- ---------- */

.card-secondary.card-parent
{
    background: #45494a;
}

.card-secondary
{
    border: 1px solid #5f666d;
}

.card-secondary .card-header
{
    background: #697179;
}

.card-secondary .card-header h3
{
    color: #ccc;
    font-weight: 400;
    margin-bottom: 0;
}

.card-secondary .card-body
{
    background: #6c757d;
}

/* ---------- ---------- ---------- */

.card-dark.card-parent
{
    background: #45494a;
}

.card-dark
{
    border: 1px solid #2e3338;
}

.card-dark .card-header
{
    background: #343a40;
}

.card-dark .card-header h3
{
    color: #ccc;
    font-weight: 400;
    margin-bottom: 0;
}

.card-dark .card-body
{
    background: #343a40;
}

/* ---------- ---------- ---------- */

.card-footer
{
    background: #f3f3f3;
}

.card-footer p
{
    color: #888;
    margin: 0;
}

/* ---------- ---------- ---------- */

.bottom-fix-class
{
    border-radius: 0 0 .25rem .25rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.inline-action-box
{
    margin: 0 0 0 .25rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.user-content-box
{
    min-height: 20rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.field-group
{
    margin: 1.25rem 0 0 0;
    border: 1px solid #7cafe6;
    padding: 1rem;
    background: #f0f8ff;
}

.field-group.special
{
    background-color: #fafad2;
}

.field-group .form-group:last-of-type
{
    margin: 1.25rem 0 0.25rem 0;
}

.field-group .form-group:only-of-type
{
    margin: 0 0 0.25rem 0;
}

.icon-preview-box
{
    background: #eee;
    border: 1px dotted #999;
    border-radius:0.25rem;
    display:inline-block;
}

span.form-error-icon
{
    vertical-align: 0.10rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.form-group { margin-bottom: 1rem; }
.form-group:last-of-type { margin: 1.25rem 0 0.25rem 0; }
.form-group.in-alert { margin: 1rem 0 0 0; }
.form-group label { margin-bottom: .25rem; }

.form-control
{
    border: 1px solid #6c757d;
}

.row-last .col .form-group
{
    margin: 0;
}

fieldset.form-group { margin: 0 0 1rem 0; }
.col-form-label { padding-top: 0; }

.form-label
{
    color: #333;
    font-weight: 700;
    margin-bottom: .25rem;
}

.form-group legend
{
    color: $primary;
    font-weight: 700;
    margin-bottom: .25rem;
}

.modal-header .close
{
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-content .form-group
{
    margin: 0;
}

.modal-choice
{
    border-top: 1px solid #dee2e6;
    padding: 1rem;
}

.close
{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}

/* ---------------------------------------------------------------------------------------------------------------- */

form#form-profile-preferences #profile_theme_theme,
form#form-profile-language #profile_language_language
{
    margin-left: 1rem;
}

form#form-profile-preferences fieldset .col-form-label,
form#form-profile-language fieldset .col-form-label
{
    color: #000;
}

form#form-profile-preferences fieldset:only-of-type,
form#form-profile-language fieldset:only-of-type
{
    margin-bottom: 0;
}

/* ---------------------------------------------------------------------------------------------------------------- */

li.tab-disabled a
{
    color: #C0C0C0;
    cursor: no-drop;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.firm-data-box p
{
    margin-bottom: 0.5rem;
}

.firm-data-box p:last-of-type
{
    margin: 0;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.bank-data-box p
{
    margin-bottom: 0.5rem;
}

.bank-data-box p:last-of-type
{
    margin: 0;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.help-message
{
    margin:0 0 20px 0;
}

.help-message p
{
    margin:0 !important;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.field-prepended
{
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.field-appended
{
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
}

/* ---------------------------------------------------------------------------------------------------------------- */

.form-login-error
{
    color: #ff0000;
    text-align: center;
}

/* ---------------------------------------------------------------------------------------------------------------- */

label.input-choice-expanded
{
    margin-bottom: 0;
}

div.input-business-form,
div.input-location-type
{
    margin-left: 15px;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- tabs 4 user list ------------------------------------------------------------------------------------ */
/* ---------------------------------------------------------------------------------------------------------------- */

#myTab.nav-tabs
{
    border-bottom: 1px solid #d5d5d5;
}

.nav-tabs .nav-link
{
    background-color: #f3f3f3;
    border: 1px solid #d5d5d5;
    color: #3078c5;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus
{
    background-color: #f3f3f3;
    border: 1px solid #d5d5d5;
    color: #333;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-bottom: 1px solid #fff;
    color: #000;
}

#box-user-list
{
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-top: none;
    padding: 1rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- NAV active tab override (MAIN MENU) ----------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------- */

#menu-main
{
    margin: 0 10px;
}

.my-menu-main.nav-tabs .nav-item
{
    /* margin-bottom: -1px; */
}

.my-menu-main.nav-tabs .nav-link
{
    background-color: #fff;
    border: 1px solid #c0c0c0;
}

.my-menu-main.nav-tabs > li > a
{
    color: #3078c5;
}

.my-menu-main.nav-tabs > li > a:hover,
.my-menu-main.nav-tabs > li > a:focus
{
    color: #333;
}

.my-menu-main.nav-tabs > li.active > a,
.my-menu-main.nav-tabs > li.active > a:hover,
.my-menu-main.nav-tabs > li.active > a:focus
{
    background-color: #fffaf0;
    color: #333;
    border-top: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-bottom: none;
    height: 42px;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- NAV active tab override (PROJECT or SUB MENU) ------------------------------------------------------------ */
/* ---------------------------------------------------------------------------------------------------------------- */

#main-holder
{
    margin: -1px 10px 0 10px;
    padding: 10px 10px 10px 10px;
    border: 1px solid #c0c0c0;
    background: #FFFAF0;
}

.my-menu-sub.nav-tabs
{
    border-bottom: 1px solid #ddd;
}

.my-menu-sub.nav-tabs .nav-item
{
    /* margin-bottom: -1px; */
}

.my-menu-sub.nav-tabs .nav-link
{
    background-color: #fff;
    border: 1px solid #ddd;
}

.my-menu-sub.nav-tabs > li > a
{
    color: #3078c5;
}

.my-menu-sub.nav-tabs > li > a:hover,
.my-menu-sub.nav-tabs > li > a:focus
{
    color: #333;
}

.my-menu-sub.nav-tabs > li.active > a,
.my-menu-sub.nav-tabs > li.active > a:hover,
.my-menu-sub.nav-tabs > li.active > a:focus
{
    color: #333;
    background-color: #f8f8f8;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: none;
    height: 42px;
}

/* ---------- no sub menu ------------------------------------------------------------------------------------------------------- */

.no-menu-sub
{
    width: 100%;
    border-bottom: 1px solid #ddd;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- (DIR MENU) ------------------------------------------------------------ */
/* ---------------------------------------------------------------------------------------------------------------- */

.my-menu-dir.nav-tabs
{
    border-bottom: none;
}

ul.my-menu-dir
{
    margin-bottom: 1rem;
}

.my-menu-dir.nav-tabs > li > a
{
    color: #3078c5;
    background: #fff;
    border-radius: 0;
    border: 1px solid #ddd;
}

.my-menu-dir.nav-tabs > li > a:hover,
.my-menu-dir.nav-tabs > li > a:focus
{
    color: #333;
    border: 1px solid #ddd;
}

/* ---------- NAV like item --------------------------------------------------------------------------- */

.my-menu-dir .item > a
{
    color: #3078c5;
    background: #fff;
    border-radius: 0;
    border: 1px solid #ddd;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- NAV disabled tab override --------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------- */

.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus
{
    color: #777777;
    text-decoration: none;
    background-color: #fff0f7;
    cursor: not-allowed;
}

/* ---------- spacer ------------------------------------------------------------------------------------------------------- */

.spacer-right
{
    margin: 0 0.5rem 0 0;
}

.spacer-left
{
    margin: 0 0 0 0.5rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* ---------- JS TREE --------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------- */

.jstree-default .jstree-anchor
{
    line-height: 1.25rem;
    height: 24px;
}

.jstree li > a
{
    font-size: 0.9375rem;
    line-height: 1.25rem;
}

.jstree li > a > .jstree-icon
{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem !important;
}

.jstree-anchor,
.jstree-anchor:active,
.jstree-anchor:hover,
.jstree-anchor:link,
.jstree-anchor:visited
{
    color: #000 !important;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.jstree li a > svg.blue,
.jstree li a.blue > .jstree-icon:before
{
    color: #0000ff;
}
.jstree li a > svg.blue-light,
.jstree li a.blue-light > .jstree-icon:before
{
    color: #007bff;
}
.jstree li a > svg.cyan,
.jstree li a.cyan > .jstree-icon:before
{
    color: #17a2b8;
}

.jstree li a > svg.purple,
.jstree li a.purple > .jstree-icon:before
{
    color: #6610f2;
}
.jstree li a > svg.indigo,
.jstree li a.indigo > .jstree-icon:before
{
    color: #6f42c1;
}
.jstree li a > svg.violet,
.jstree li a.violet > .jstree-icon:before
{
    color: #bcbcff;
}

.jstree li a > svg.red,
.jstree li a.red > .jstree-icon:before
{
    color: #dc3545;
}
.jstree li a > svg.pink,
.jstree li a.pink > .jstree-icon:before
{
    color: #e83e8c;
}
.jstree li a > svg.magenta,
.jstree li a.magenta > .jstree-icon:before
{
    color: #FF00FF;
}

.jstree li a > svg.brown,
.jstree li a.brown > .jstree-icon:before
{
    color: #804400;
}
.jstree li a > svg.orange,
.jstree li a.orange > .jstree-icon:before
{
    color: #fd7e14;
}
.jstree li a > svg.yellow,
.jstree li a.yellow > .jstree-icon:before
{
    color: #ffc107;
}

.jstree li a > svg.green,
.jstree li a.green > .jstree-icon:before
{
    color: #008000;
}
.jstree li a > svg.green-light,
.jstree li a.green-light > .jstree-icon:before
{
    color: #28a745;
}
.jstree li a > svg.teal,
.jstree li a.teal > .jstree-icon:before
{
    color: #20c997;
}

.jstree li a > svg.black,
.jstree li a.black > .jstree-icon:before
{
    color: #000;
}
.jstree li a > svg.gray-5,
.jstree li a.gray-5 > .jstree-icon:before
{
    color: #555;
}
.jstree li a > svg.gray-9,
.jstree li a.gray-9 > .jstree-icon:before
{
    color: #999;
}
.jstree li a > svg.gray-c,
.jstree li a.gray-c > .jstree-icon:before
{
    color: #ccc;
}

/* ----------------------------------------------------------------------------------------------------------------- */

#file-tree
{
    display: none;
}

#file-tree .lnr
{
    margin-right: 0.25rem !important
}

#file-tree > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

#file-tree-download > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-download > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

#file-tree-restore-2.restore-files > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-restore-2.restore-files > .jstree-container-ul > .jstree-node > a > .jstree-checkbox { display:none }
#file-tree-restore-2.restore-files > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

.tree-search-block
{
    margin-bottom: 1.25rem;
}

.control-prepend-search
{
    color: #6c757d;
    border-color: #6c757d;
}

/* ---------- ---------- ---------- */

#file-tree-share > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-share > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

#file-tree-delete > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-delete > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

#file-tree-restore-1 > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-restore-1 > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }
#file-tree-restore-2 > .jstree-container-ul > .jstree-node > .jstree-ocl { display:none }
#file-tree-restore-2 > .jstree-container-ul > .jstree-node > .jstree-children > .jstree-node { margin-left:0; }

/* ----------------------------------------------------------------------------------------------------------------- */

#file-tree-buttons,
#file-tree-delete,
#file-tree-restore-1,
#file-tree-restore-2,
#file-tree-share,
#file-tree-download
{
    display: none;
    margin-top: 1rem;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.js-file-tree-loader
{
    margin-bottom: 1rem;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.lnr
{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem !important;
}

.lnr-small
{
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem !important;
}

th i.lnr,
td i.lnr
{
    vertical-align: -0.15rem;
    margin-right: 0.15rem;
}

.lnr.self-in-row
{
    vertical-align:text-top;
}

/* ---------- INFO PANEL ------------------------------------------------------------------------------------------------------- */

#box-info-panel
{
    background: #b6a68c;
    background: rgba(182,166,140,1);
    border-bottom: 1px solid #736958;
    border-top: 1px solid #736958;
    height: auto;
    padding: 0.5rem 0.75rem 0.75rem 5rem;
    margin: 0 -10px 10px -10px;
}

.info-panel-default { background:rgba(182,110,140,1); }
.info-panel-compass { background:rgba(182,110,140,1) url("../static/img/ui/info-panel-compass-1.png") 5px 1px no-repeat !important; }

/*
sand theme
.info-panel-default { background:rgba(182,166,140,1); }
.info-panel-compass { background:rgba(182,166,140,1) url("../static/img/ui/user-info-panel-compass.png") 5px 1px no-repeat !important; }
*/

#box-info-panel p.info
{
    padding:0;
    margin:0;
    color: #e7f4f9;
}

#box-info-panel #box-info-data .info-name,
#box-info-panel #box-info-data .info-code-name
{
    color: #fdebbf;
}

#box-info-panel #box-info-path .info-selected-dir
{
    color: #fffead;
}

#box-info-panel #box-info-path .info-panel-path-dir-separator
{
    color: #e7f4f9;
}

/* ---------- access effective ------------------------------------------------------------------------------------------------------- */

.node-path-separator
{
    color: #999;
}

/* ---------- PROJECT ------------------------------------------------------------------------------------------------------- */

.project-list
{
}

.project
{
    background-color: #fff;
    border: 1px solid #aaa;
    margin: 0 0.5rem 0.5rem 0;
    padding: 1rem;
    min-width: 18rem;
    min-height: 8rem;
    float: left;
}

.project.project-activated
{
    border-top:2px solid #008000;
}

.project.project-not-activated
{
    border-top:2px solid #FF0000;
}

.project.project-maintenance
{
    border-top:2px solid #ff8c00;
}

.project p
{
    margin:0;
    padding:0;
}

.project-status-indicator
{
    background-color: #ea868f;
    border: 1px solid #000;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    vertical-align: -0.2rem;
}

.project-status-indicator.blocked
{
    /* red - e35d6a */
    background-color: #ea868f;
}

.project-status-indicator.maintenance
{
    /* yellow - ffda6a */
    background-color: #ffe69c;
}

.project-status-indicator.active
{
    /* green - 479f76 */
    background-color: #75b798;
}

/* ---------- table items ------------------------------------------------------------------------------------------------------- */

.table.items
{
    background: #fff;
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    color: #000;
    margin-bottom: 0;
}

.table > :not(:last-child) > :last-child > *
{
    border-bottom-color: #dee2e6;
    border-bottom-width: 2px;
}

.table.items th.item-type
{
    width: 3rem;
}

.table.items th.item-icon
{
    width: 6rem;
}

.table.items th.item-size
{
    width: 9rem;
}

.table.items th.item-time
{
    width: 12rem;
}

.table.items th.item-actions
{
    width: 10rem;
}

.table.items th.item-actions-wide-1
{
    width: 15rem;
}

.table.items th.item-actions-wide-2
{
    width: 17rem;
}

.table.items th.item-actions-wide-3
{
    width: 20rem;
}

.table.items th.item-access-projects
{
    width: 20%;
}

.table.items th.item-access-firms,
.table.items th.item-access-users
{
    min-width: 40%;
}

.table.items th.item-access-actions
{
    width: 10%;
}

tr.special
{
    background-color: #fafad2;
}

tr i.red
{
    color: #F00;
}

tr i.green
{
    color: #008000;
}

tr i.blue
{
    color: #00F;
}

tr i.brown
{
    color: #804400;
}

tr i.blue-light
{
    color: #007bff;
}

tr i.cyan
{
    color: #17a2b8;
}

tr i.purple
{
    color: #6610f2;
}

tr i.indigo
{
    color: #6f42c1;
}

tr i.violet
{
    color: #bcbcff;
}

tr i.red-2
{
    color: #dc3545;
}

tr i.pink
{
    color: #e83e8c;
}

tr i.magenta
{
    color: #FF00FF;
}

tr i.brown
{
    color: #804400;
}

tr i.orange
{
    color: #fd7e14;
}

tr i.yellow
{
    color: #ffc107;
}

tr i.green
{
    color: #008000;
}

tr i.green-light
{
    color: #28a745;
}

tr i.teal
{
    color: #20c997;
}

tr i.black
{
    color: #000;
}

tr i.gray-5
{
    color: #555;
}

tr i.gray-9
{
    color: #999;
}

tr i.gray-c
{
    color: #ccc;
}

td.actions a
{
    text-decoration: none;
}

.before-table
{
    margin-bottom: 1rem;
}

table.table-even-columns
{
    table-layout: fixed;
}

td.border-separator-left
{
    border-left: 1px solid #ccc;
}

/* ---------- td side coloring ------------------------------------------------------------------------------------------------------- */

th.item-active,
td.item-active,
td.project-active,
td.project-success
{
    border-left: 1px solid #28a745;
    padding-left: 0.5rem;
}

td.project-maintenance,
td.project-orange
{
    border-left: 1px solid #ff8c00;
    padding-left: 0.5rem;
}

th.item-not-active,
td.item-not-active,
td.project-not-active,
td.project-danger
{
    border-left: 1px solid #dc3545;
    padding-left: 0.5rem;
}

td.project-primary
{
    border-left: 1px solid #007bff;
    padding-left: 0.5rem;
}

td.project-info
{
    border-left: 1px solid #17a2b8;
    padding-left: 0.5rem;
}

td.project-magenta
{
    border-left: 1px solid #ff006e;
    padding-left: 0.5rem;
}

td.project-purple
{
    border-left: 1px solid #563d7c;
    padding-left: 0.5rem;
}

/* ---------- access table ------------------------------------------------------------------------------------------------------- */

.access table .lnr
{
    vertical-align: text-bottom;
}

.access ul.address-actual,
.access ul.address-legal
{
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 2rem;
}

.access ul.project-firms,
.access ul.project-users
{
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 2rem;
}

/* ---------- activity table ------------------------------------------------------------------------------------------------------- */

.table th.item-activity.event-user
{
    width: 15rem;
}

.table th.item-activity.event-subject.event-type
{
    width: 12rem;
}

.table th.item-activity.event-date-time
{
    width: 15rem;
}

.table th.item-activity.event-time
{
    width: 12rem;
}

.table th.item-activity.event-ip
{
    width: 10rem;
}

/* ---------- file info ------------------------------------------------------------------------------------------------------- */

.file-info
{
    margin-bottom: 1.25rem;
}

.file-info p
{
    margin-bottom: 0.5rem;
}

.clean-up-bottom
{
    margin-bottom: 0;
}

.box-btn
{
    margin-top: 1.25rem;
}

.btn i
{
    margin-right: 0.25rem;
}

/* ---------- file error ------------------------------------------------------------------------------------------------------- */

#file-error p
{
    margin-bottom: 0.25rem;
}

/* ---------- uploader styles ------------------------------------------------------------------------------------------------------- */

.fade.in {
    opacity: 1
}

/* ---------- SVG icons -- access tree ------------------------------------------------------------------------------------------------------- */

.access-icon-box
{
    margin-left: .15rem;
}

.access-icon-box svg
{
    margin-right: 0;
    margin-left: .15rem;
}

.access-icon-box svg.ml-alt
{
    margin-right: 0;
    margin-left: .1rem;
}

/* ---------- FontAwesome icons -- menu and buttons ------------------------------------------------------------------------------------------------------- */

svg
{
    margin-right: .15rem;
}

.btn-icon-right i,
.btn-icon-right svg
{
    margin-right: 0;
    margin-left: 0.25rem;
}

.btn-icon-left i,
.btn-icon-left svg
{
    margin-right: 0.25rem;
    margin-left: 0;
}

.link-icon-right svg
{
    margin-right: 0;
    margin-left: 0.15rem;
}

.link-icon-left svg
{
    margin-right: 0.15rem;
    margin-left: 0;
}

.btn-bg-gray
{
    background: #eeeeee;
}

.link-icon-right i
{
    margin-right: 0;
    margin-left: 0.25rem;
}

.link-icon-left i
{
    margin-left: 0;
    margin-right: 0.25rem;
}

.start.link-icon-right i
{
    margin-right: 0;
    margin-left: 0.5rem;
}

.start.link-icon-left i
{
    margin-left: 0;
    margin-right: 0.5rem;
}

/* ---------- additional text colors ------------------------------------------------------------------------------------------------------- */

.text-sand
{
    color: #dc9f35;
}

.text-orange
{
    color: #ff8c00;
}

.text-blue-light
{
    color: #00bfff;
}

.text-cyan
{
    color: #0aa2c0;
}

.text-magenta
{
    color: #ff006e;
}

.text-purple
{
    color: #563d7c;
}

.text-gray-5
{
    color: #555555;
}

.text-gray-9
{
    color: #999999;
}

.text-gray-a
{
    color: #aaaaaa;
}

.text-black
{
    color: #000;
}

/* ---------- default list ------------------------------------------------------------------------------------------------------- */

ul.notification-list,
ul.list
{
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 2rem;
}

ul.notification-list li p
{
    margin: 0;
    padding: 0;
}

select option
{
    background: #fff;
}

/* ---------- selection ------------------------------------------------------------------------------------------------------- */

::selection
{
    color: #fff;
    background: #333;
    text-decoration: none;
    text-shadow: none;
}

/* ---------- visually hidden ------------------------------------------------------------------------------------------------------- */

.visually-hidden
{
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

/* ---------- folder file icon choice tooltip ------------------------------------------------------------------------------------------------------- */

#folder-file-icon-choice-tooltip a
{
    text-decoration: none;
    cursor: pointer;
}

#folder-file-icon-choice-tooltip a span:active,
#folder-file-icon-choice-tooltip a span:hover
{
    color: $success;
    text-decoration: none;
}

.tippy-content
{
    padding: .25rem !important;
}
